export const PRICE_LIST = {
  AIRBNB: {
    'APARTMENT/BASEMENT': {
      1: {
        1: {
          amount: 80,
          hours: 2,
        },
        2: {
          amount: 85,
          hours: 2,
        },
        3: {
          amount: 90,
          hours: 2,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 75,
        foggingServices: 65,
      },
      2: {
        1: {
          amount: 95,
          hours: 2.5,
        },
        2: {
          amount: 100,
          hours: 2.5,
        },
        3: {
          amount: 105,
          hours: 2.5,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 75,
        foggingServices: 80,
      },
      3: {
        1: {
          amount: 110,
          hours: 3,
        },
        2: {
          amount: 115,
          hours: 3,
        },
        3: {
          amount: 120,
          hours: 3,
        },
        4: {
          amount: 125,
          hours: 3,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 75,
        foggingServices: 95,
      },
      4: {
        1: {
          amount: 130,
          hours: 4,
        },
        2: {
          amount: 135,
          hours: 4,
        },
        3: {
          amount: 140,
          hours: 4,
        },
        4: {
          amount: 145,
          hours: 4,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 110,
      },
    },
    'HOUSE/TOWNHOUSE': {
      1: {
        1: {
          amount: 105,
          hours: 3,
        },
        2: {
          amount: 110,
          hours: 3,
        },
        3: {
          amount: 115,
          hours: 3,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 100,
      },
      2: {
        1: {
          amount: 140,
          hours: 4,
        },
        2: {
          amount: 145,
          hours: 4,
        },
        3: {
          amount: 150,
          hours: 4,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 120,
      },
      3: {
        1: {
          amount: 180,
          hours: 5.5,
        },
        2: {
          amount: 185,
          hours: 5.5,
        },
        3: {
          amount: 190,
          hours: 5.5,
        },
        3: {
          amount: 195,
          hours: 5.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 150,
      },
      4: {
        1: {
          amount: 200,
          hours: 6.5,
        },
        2: {
          amount: 205,
          hours: 6.5,
        },
        3: {
          amount: 210,
          hours: 6.5,
        },
        4: {
          amount: 215,
          hours: 6.5,
        },
        5: {
          amount: 220,
          hours: 6.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 180,
      },
      5: {
        1: {
          amount: 225,
          hours: 7.5,
        },
        2: {
          amount: 230,
          hours: 7.5,
        },
        3: {
          amount: 235,
          hours: 7.5,
        },
        4: {
          amount: 240,
          hours: 7.5,
        },
        5: {
          amount: 245,
          hours: 7.5,
        },
        6: {
          amount: 250,
          hours: 7.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 100,
        foggingServices: 220,
      },
      6: {
        1: {
          amount: 255,
          hours: 8.5,
        },
        2: {
          amount: 260,
          hours: 8.5,
        },
        3: {
          amount: 265,
          hours: 8.5,
        },
        4: {
          amount: 270,
          hours: 8.5,
        },
        5: {
          amount: 275,
          hours: 8.5,
        },
        6: {
          amount: 280,
          hours: 8.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 150,
        foggingServices: 250,
      },
      7: {
        1: {
          amount: 285,
          hours: 9.5,
        },
        2: {
          amount: 290,
          hours: 9.5,
        },
        3: {
          amount: 295,
          hours: 9.5,
        },
        4: {
          amount: 300,
          hours: 9.5,
        },
        5: {
          amount: 305,
          hours: 9.5,
        },
        6: {
          amount: 310,
          hours: 9.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 150,
        foggingServices: 280,
      },
      8: {
        1: {
          amount: 315,
          hours: 10.5,
        },
        2: {
          amount: 320,
          hours: 10.5,
        },
        3: {
          amount: 325,
          hours: 10.5,
        },
        4: {
          amount: 330,
          hours: 10.5,
        },
        5: {
          amount: 335,
          hours: 10.5,
        },
        6: {
          amount: 340,
          hours: 10.5,
        },
        7: {
          amount: 345,
          hours: 10.5,
        },
        8: {
          amount: 350,
          hours: 10.5,
        },
        insideOven: 60,
        insideCabinets: 60,
        interiorWindows: 150,
        foggingServices: 300,
      },
    },
  },

  MOVING: {
    'APARTMENT/BASEMENT': {
      1: {
        '1-3': {
          amount: 135,
          hours: 4.5,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      2: {
        '1-3': {
          amount: 165,
          hours: 5.5,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      3: {
        '1-3': {
          amount: 195,
          hours: 6.5,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      4: {
        '1-3': {
          amount: 225,
          hours: 7.5,
        },
        4: {
          amount: 240,
          hours: 8,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 60,
      },
    },
    'HOUSE/TOWNHOUSE': {
      1: {
        '1-3': {
          amount: 150,
          hours: 5,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      2: {
        '1-3': {
          amount: 180,
          hours: 6,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      3: {
        '1-2': {
          amount: 210,
          hours: 7,
        },
        3: {
          amount: 240,
          hours: 8,
        },
        insideOven: 15,
        insideCabinets: 15,
        interiorWindows: 45,
      },
      4: {
        '1-3': {
          amount: 240,
          hours: 8,
        },
        4: {
          amount: 255,
          hours: 8.5,
        },
        5: {
          amount: 270,
          hours: 9,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 60,
      },
      5: {
        '1-3': {
          amount: 270,
          hours: 9,
        },
        4: {
          amount: 285,
          hours: 9.5,
        },
        5: {
          amount: 315,
          hours: 10.5,
        },
        insideOven: 30,
        insideCabinets: 30,
        interiorWindows: 60,
      },
      6: {
        '1-3': {
          amount: 330,
          hours: 11,
        },
        4: {
          amount: 345,
          hours: 11.5,
        },
        5: {
          amount: 360,
          hours: 12,
        },
        6: {
          amount: 375,
          hours: 12.5,
        },
        insideOven: 30,
        insideCabinets: 45,
        interiorWindows: 75,
      },
      7: {
        '1-3': {
          amount: 405,
          hours: 13.5,
        },
        4: {
          amount: 420,
          hours: 14,
        },
        5: {
          amount: 435,
          hours: 14.5,
        },
        6: {
          amount: 450,
          hours: 15,
        },
        insideOven: 30,
        insideCabinets: 45,
        interiorWindows: 75,
      },
      8: {
        '1-3': {
          amount: 465,
          hours: 15.5,
        },
        4: {
          amount: 480,
          hours: 16,
        },
        5: {
          amount: 495,
          hours: 16.5,
        },
        6: {
          amount: 510,
          hours: 17,
        },
        7: {
          amount: 525,
          hours: 17.5,
        },
        insideOven: 30,
        insideCabinets: 45,
        interiorWindows: 75,
      },
    },
  },
};
