import { gql } from 'apollo-boost';

export const ORDERS = gql`
  query orders(
    $keyword: String
    $ID: String
    $page: Int
    $service: Int
    $create: Int
    $sort: String
  ) {
    orders(
      keyword: $keyword
      id: $ID
      page: $page
      service: $service
      create: $create
      sort: $sort
    ) {
      id
      status
      cleanHours
      serviceType
      roomType
      bedroom
      bathroom
      extraServices
      street
      apartment
      city
      province
      serviceDateTime
      checkInTime
      homeAccess
      homeAccessNotes
      outstanding
      customer {
        firstName
        lastName
        email
      }
      phoneNumber
      promoCode
      subTotal
      tax
      total
      createdAt
      cleaner {
        firstName
        lastName
      }
      payrollStatus
    }
  }
`;

export const UPDATE_ORDER_ADMIN = gql`
  mutation updateOrderAdmin(
    $id: ID!
    $phoneNumber: String!
    $street: String!
    $apartment: String
    $city: String!
    $province: String!
    $postCode: String!
    $serviceType: String!
    $roomType: String!
    $bedroom: String!
    $bathroom: String!
    $serviceDateTime: String!
    $checkInTime: String!
    $homeAccess: String!
    $homeAccessNotes: String
    $extraServices: [String]
    $subTotal: Float!
    $tax: Float!
    $total: Float!
    $promoCode: String
    $outstanding: Float
    $hours: String!
    $discount: Float
    $status: String!
    $payrollStatus: String
    $keycafe_address: String
    $keycafe_code: String
  ) {
    updateOrderAdmin(
      id: $id
      discount: $discount
      hours: $hours
      phoneNumber: $phoneNumber
      street: $street
      apartment: $apartment
      city: $city
      province: $province
      postCode: $postCode
      serviceType: $serviceType
      roomType: $roomType
      bedroom: $bedroom
      bathroom: $bathroom
      extraServices: $extraServices
      serviceDateTime: $serviceDateTime
      checkInTime: $checkInTime
      homeAccess: $homeAccess
      homeAccessNotes: $homeAccessNotes
      subTotal: $subTotal
      tax: $tax
      total: $total
      promoCode: $promoCode
      outstanding: $outstanding
      status: $status
      payrollStatus: $payrollStatus
      keycafe_address: $keycafe_address
      keycafe_code: $keycafe_code
    ) {
      id
    }
  }
`;

export const ASSIGN_ORDER_ADMIN = gql`
  mutation assignOrderAdmin($id: ID!, $cleanerEmail: String!) {
    assignOrderAdmin(id: $id, cleanerEmail: $cleanerEmail) {
      id
      cleaner {
        id
        firstName
      }
    }
  }
`;
export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: ID
    $phoneNumber: String!
    $serviceDateTime: String!
    $checkInTime: String!
    $homeAccess: String!
    $homeAccessNotes: String!
    $extraServices: [String]
    $subTotal: Float!
    $tax: Float!
    $total: Float!
    $promoCode: String
    $hour: String!
    $discount: Float
  ) {
    updateOrder(
      id: $id
      discount: $discount
      phoneNumber: $phoneNumber
      extraServices: $extraServices
      serviceDateTime: $serviceDateTime
      checkInTime: $checkInTime
      homeAccess: $homeAccess
      homeAccessNotes: $homeAccessNotes
      subTotal: $subTotal
      tax: $tax
      total: $total
      promoCode: $promoCode
      hours: $hours
    ) {
      id
      cleaner {
        id
      }
    }
  }
`;

export const SIGN_UP_AND_CREATE_ORDER = gql`
  mutation signUpAndCreateOrder(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $phoneNumber: String!
    $street: String!
    $apartment: String
    $city: String!
    $province: String!
    $postCode: String!
    $serviceType: String!
    $roomType: String!
    $bedroom: String!
    $bathroom: String!
    $serviceDateTime: String!
    $checkInTime: String!
    $homeAccess: String!
    $homeAccessNotes: String
    $extraServices: [String]
    $subTotal: Float!
    $tax: Float!
    $total: Float!
    $promoCode: String
    $hour: String!
    $discount: Float
  ) {
    signUpAndCreateOrder(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      street: $street
      apartment: $apartment
      city: $city
      province: $province
      postCode: $postCode
      serviceType: $serviceType
      roomType: $roomType
      bedroom: $bedroom
      bathroom: $bathroom
      extraServices: $extraServices
      serviceDateTime: $serviceDateTime
      checkInTime: $checkInTime
      homeAccess: $homeAccess
      hours: $hours
      homeAccessNotes: $homeAccessNotes
      subTotal: $subTotal
      tax: $tax
      total: $total
      promoCode: $promoCode
      discount: $discount
      discountValue: $discountValue
      beforeDiscountSubTotal: $beforeDiscountSubTotal
    ) {
      id
      token
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $phoneNumber: String!
    $street: String!
    $apartment: String
    $city: String!
    $province: String!
    $postCode: String!
    $serviceType: String!
    $roomType: String!
    $bedroom: String!
    $bathroom: String!
    $serviceDateTime: String!
    $checkInTime: String!
    $homeAccess: String!
    $homeAccessNotes: String
    $extraServices: [String]
    $subTotal: Float!
    $tax: Float!
    $total: Float!
    $promoCode: String
    $hour: String!
    $discount: Float
    $discountValue: Float
    $beforeDiscountSubTotal: Float
  ) {
    createOrder(
      phoneNumber: $phoneNumber
      street: $street
      apartment: $apartment
      city: $city
      province: $province
      postCode: $postCode
      serviceType: $serviceType
      roomType: $roomType
      bedroom: $bedroom
      bathroom: $bathroom
      extraServices: $extraServices
      serviceDateTime: $serviceDateTime
      checkInTime: $checkInTime
      homeAccess: $homeAccess
      homeAccessNotes: $homeAccessNotes
      subTotal: $subTotal
      tax: $tax
      total: $total
      hours: $hours
      promoCode: $promoCode
      discount: $discount
      discountValue: $discountValue
      beforeDiscountSubTotal: $beforeDiscountSubTotal
    ) {
      id
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!, $outstanding: Float) {
    cancelOrder(id: $id, outstanding: $outstanding) {
      id
      cleaner {
        id
        firstName
      }
    }
  }
`;

export const ASSIGN_ORDER = gql`
  mutation assignOrder($id: ID!) {
    assignOrder(id: $id) {
      id
      cleaner {
        id
        firstName
      }
    }
  }
`;

export const COMPLETED_ORDER = gql`
  mutation completeOrder(
    $id: ID!
    $suppliesHealth: [String]
    $suppliesLaundry: [String]
    $suppliesCleaning: [String]
    $pillowCase: String
    $queenSizeBedsheet: String
    $kingSizeBedsheet: String
    $queenSizeDuvetCover: String
    $kingSizeDuvetCover: String
    $bathTowels: String
    $faceCloth: String
    $suppliesNone: Boolean
  ) {
    completeOrder(
      id: $id
      suppliesHealth: $suppliesHealth
      suppliesLaundry: $suppliesLaundry
      suppliesCleaning: $suppliesCleaning
      pillowCase: $pillowCase
      queenSizeBedsheet: $queenSizeBedsheet
      kingSizeBedsheet: $kingSizeBedsheet
      queenSizeDuvetCover: $queenSizeDuvetCover
      kingSizeDuvetCover: $kingSizeDuvetCover
      bathTowels: $bathTowels
      faceCloth: $faceCloth
      suppliesNone: $suppliesNone
    ) {
      id
      cleaner {
        id
        firstName
      }
    }
  }
`;
export const PENDING_ORDER = gql`
  mutation pendingOrder($id: ID!) {
    pendingOrder(id: $id) {
      id
      cleaner {
        id
        firstName
      }
    }
  }
`;

export const EMAIL_INVOICE = gql`
  mutation emailInvoice($id: ID!) {
    emailInvoice(id: $id) {
      id
    }
  }
`;

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      suppliesNone
      suppliesHealth
      suppliesLaundry
      suppliesCleaning
      pillowCase
      queenSizeBedsheet
      kingSizeBedsheet
      queenSizeDuvetCover
      kingSizeDuvetCover
      bathTowels
      faceCloth
      status
      serviceType
      roomType
      bedroom
      bathroom
      extraServices
      street
      apartment
      city
      province
      serviceDateTime
      homeAccess
      homeAccessNotes
      outstanding
      hours
      discount
      cleanHours
      customer {
        firstName
        lastName
        email
      }
      postCode
      phoneNumber
      promoCode
      subTotal
      tax
      total
      cleaner {
        id
        firstName
        lastName
        email
        cleanerInfo {
          phoneNumber
        }
      }
      status
      picture
      payrollStatus
      keycafe_address
      keycafe_code
      specialRequest
      ft
      checkInTime
      discountValue
      fine
    }
  }
`;

export const ME_ORDERS = gql`
  query me_orders($orderStatus: String) {
    me_orders(orderStatus: $orderStatus) {
      id
      createdAt
      street
      serviceType
      extraServices
      hours
      discount
      cleaner {
        id
        firstName
        lastName
      }
      total
      status
    }
  }
`;

export const ME_ORDERS_NOT_COMPLETED = gql`
  query {
    me_orders_not_completed {
      id
      createdAt
      street
      hours
      discount
      serviceType
      extraServices
      discount
      cleaner {
        id
        firstName
        lastName
      }
      total
      status
      fine
    }
  }
`;
